import React,{useState, useEffect} from "react";
import AuthApi from "../api/auth/index.js";
import { useNavigate } from "react-router-dom";

function Login (){

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const navigate = useNavigate();

    const Auth = (e) => {
        e.preventDefault();
        if(!email) {
            setMsg('Masukan Alamat Email Anda!');
        }

        if(!password) {
            setMsg('Masukan Password Anda!');
        }

        
        try {

            const data = {
                email : email,
                password : password
            }
    
            AuthApi.login(data).then((res) => {
                if(res.status == 200){
                    setMsg('');
                    alert('sukses')
                    navigate('/');
                }else{
                    setMsg('Email atau password salah!');
                }
            })
        
        } catch (error) {
            if(error.response) {
                setMsg(error.response.data.msg);
            }
        }
    }

    return(
        <div className="d-flex justify-content-center align-items-center" style={{height:'100vh'}}>
            <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                <div className="card card-plain" style={{
                background: 'hsla(0, 0%, 100%, 0.55)',
                backdropFilter: 'blur(30px)'
                }}>

                    <div className="card-body">
                        
                        <h4 className="font-weight-bolder text-center">Sign In</h4>
                        <p className="mb-0  text-center">Enter your email and password to sign in</p>

                        {msg ?(                                                
                            <div className="bg-danger text-center p-2 my-1 rounded">
                                <p className="text-white">
                                    {msg}
                                </p>
                            </div>
                            ) : (
                                <></>
                            )
                        }
                        <form onSubmit={(e) => Auth(e)}>
                            <div className="flex flex-col mb-3">
                                <input onChange={(e) => setEmail(e.target.value)} type="email" name="email" className="form-control form-control-lg" placeholder="Input Email" aria-label="Email" value={email} required/>
                            </div>
                            <div className="flex flex-col mb-3">
                                <input onChange={(e) => setPassword(e.target.value)} type="password" name="password" className="form-control form-control-lg" placeholder="Input Password" aria-label="Password" value={password} required/>
                            </div>
                            <div className="form-check form-switch">
                                <input className="form-check-input" name="remember" type="checkbox" id="rememberMe"/>
                                <label className="form-check-label" for="rememberMe">Remember me</label>
                            </div>
                            <div className="text-center">
                                <button className="btn btn-lg btn-danger btn-lg w-100 mt-4 mb-0">Log in</button>
                            </div>
                        </form>
                    </div>                        
                </div>
            </div>
        </div>
    )
}

export { Login };