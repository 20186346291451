import axios from "axios";
import AuthUser from "../auth/index";
const apiUrl = process.env.REACT_APP_API_URL;
const url = apiUrl;

const all = async() => {
    try {
        const result = await axios.get(`${url}/api/allUser`)
        return result.data
    } catch (error) {
        return await error.message
    }
}

const winCount = async() => {
    try {
        const result = await axios.get(`${url}/api/all/winner/count`)
        return result.data
    } catch (error) {
        return await error.message
    }
}

const update = async(data, expire)=>{

    // const axiosJwt = axios.create();

    // let token = '';

    // axiosJwt.interceptors.request.use(async(config) => {
    //     const currentDate = new Date();
    //     if(expire * 1000 < currentDate.getTime()){
    //         AuthUser.refreshToken().then((e) => {
    //             config.headers.Authorization = `Bearer ${e}`;
    //             token = e;
    //             // setToken(e);
    //             // const decoded = jwtDecode(e);
    //             // setExpire(decoded.exp);
    //         });  
    //     }
    //     return config;
    // }, (error) => {return Promise.reject(error)})

    try {
        const result = await axios.post(`${url}/api/update/winner`, data)
        return result
    } catch (error) {
        return await error.message
    }
}

export default {
    all,
    update,
    winCount
}
