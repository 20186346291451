import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;
const url = apiUrl;

const login = async(data) => {
    try {
        const result = await axios.post(`${url}/login`, data)
        return result
    } catch (error) {
        return await error.message
    }
}

const refreshToken = async() => {
    try {
        const result = await axios.get(`${url}/token`);
        // console.log(result);
        return result.data.accessToken
    } catch (error) {
        return false
    }
}

export default {
    login,
    refreshToken
}