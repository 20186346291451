// import logo from './logo.svg';
import './App.css';
import Routes from './Routes';

function App() {
  return (
    Routes()
  );
}

export default App;
