import React, { useState, useEffect, useRef } from 'react';
import IndexUserAPI from "../../api/dashboard/index";
import AuthUser from "../../api/auth/index";
import { useCountUp } from "use-count-up";
import logo from "../../img/infinite-logo.png";
import ktLogo from "../../img/kramat-tunggak-logo.png";
import ig from "../../img/instagram.png";
import tt from "../../img/tiktok.png";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import roll from '../../audio/roll.mp3';
import reroll from '../../audio/reroll.mp3';
import getwinner from '../../audio/getwinner.mp3';


function Dashboard() {

    const [refresh, setRefresh] = useState(false);
    const [users, setUsers] = useState([]);
    const [disableButt, setDisableButt] = useState(false);
    const [addWinner, setAddWinner] = useState(false);
    const [winnerData, setWinnerData] = useState([]);
    const [numbers, setNumbers] = useState({name : '---', instagram: '---', tiktok: '---'});
    const [numbers2, setNumbers2] = useState({name : '---', instagram: '---', tiktok: '---'});
    const [numbers3, setNumbers3] = useState({name : '---', instagram: '---', tiktok: '---'});
    const [numbers4, setNumbers4] = useState({name : '---', instagram: '---', tiktok: '---'});
    const [numbers5, setNumbers5] = useState({name : '---', instagram: '---', tiktok: '---'});
    const [counters, setCounters] = useState(0);
    const [isAnimationRunning, setIsAnimationRunning] = useState(false);

    const [token, setToken] = useState();
    const [expire, setExpire] = useState();
    const navigate = useNavigate();
    const audioRefRoll = useRef(null);
    const audioRefReRoll = useRef(null);
    const audioRefGetWinner = useRef(null);  
    
    // const toggleAnimation = () => {
    //     setIsAnimationRunning(!isAnimationRunning);
    // };
  
    const playAudioRoll = () => {
        audioRefRoll.current.currentTime = 0;
        audioRefRoll.current.play();
    };
  
    const stopAudioRoll = () => {
        audioRefRoll.current.pause();
    };

    const playAudioGetWinner = () => {
        audioRefGetWinner.current.currentTime = 0;
        audioRefGetWinner.current.play();
    };
    
    const playAudiReRoll = () => {
        audioRefReRoll.current.currentTime = 0;
        audioRefReRoll.current.play();
    };

    useEffect(() => {
        IndexUserAPI.all().then(data => {
            setUsers(data)
        })
        IndexUserAPI.winCount().then(data => {
            setCounters(data)
        })
        
        document.title = "Undian Tiket Nonton Kramat Tunggak";
    
        setRefresh(false)
    },[refresh])

    useEffect(() => {

        try {
            AuthUser.refreshToken().then((e) => {
                if(e){
                    setToken(e);
                    const decoded = jwtDecode(e);
                    setExpire(decoded.exp);                
                }else{
                    navigate('/login');
                }
            });        
        } catch (error) {
            navigate('/login');
        }

    },[])

    const { value, reset } = useCountUp({
        isCounting: true,
        start : 0,
        end : counters,
        duration : 1,
        easing : 'linear'
      });

    function handleClick() {
        playAudioRoll()
        setIsAnimationRunning(true);

        if(users.length < 1){
            return false;
        }
        setDisableButt(true);
        // const data = [];
        let result1 ='';
        let result2 ='';
        let result3 ='';
        let result4 ='';
        let result5 ='';

        const intervalId = setInterval(() => {                     
            let value = Math.floor(Math.random() * users.length);
            console.log(users[value]);
            setNumbers({
                id : users[value] ? users[value].id : null,
                name : users[value] ? users[value].name : '------------------',
                instagram : users[value] ? users[value].instagram : '------------------',
                tiktok : users[value] ? users[value].tiktok : '------------------'
            })
            result1 = users[value] ? value : null;
        }, 10)
        
        const intervalId2 = setInterval(() => {                     
            let value = Math.floor(Math.random() * users.length);
            setNumbers2({
                id : users[value] ? users[value].id : null,
                name : users[value] ? users[value].name : '------------------',
                instagram : users[value] ? users[value].instagram : '------------------',
                tiktok : users[value] ? users[value].tiktok : '------------------'
            })
            result2 = users[value] ? value : null;
        }, 10)

        const intervalId3 = setInterval(() => {                     
            let value = Math.floor(Math.random() * users.length);
            setNumbers3({
                id : users[value] ? users[value].id : null,
                name : users[value] ? users[value].name : '------------------',
                instagram : users[value] ? users[value].instagram : '------------------',
                tiktok : users[value] ? users[value].tiktok : '------------------'
            })
            result3 = users[value] ? value : null;
        }, 10)

        const intervalId4 = setInterval(() => {                     
            let value = Math.floor(Math.random() * users.length);
            setNumbers4({
                id : users[value] ? users[value].id : null,
                name : users[value] ? users[value].name : '------------------',
                instagram : users[value] ? users[value].instagram : '------------------',
                tiktok : users[value] ? users[value].tiktok : '------------------'
            })
            result4 = users[value] ? value : null;
        }, 10)

        const intervalId5 = setInterval(() => {                     
            let value = Math.floor(Math.random() * users.length);
            setNumbers5({
                id : users[value] ? users[value].id : null,
                name : users[value] ? users[value].name : '------------------',
                instagram : users[value] ? users[value].instagram : '------------------',
                tiktok : users[value] ? users[value].tiktok : '------------------'
            })
            result5 = users[value] ? value : null;
        }, 10)
    
        setTimeout( () => {
            playAudioGetWinner();            
            clearInterval(intervalId)
            setIsAnimationRunning(false);
            if(result1 !== null)winnerData.push(result1);
            getValueAndResetIndex(result1)
     
        }, 7000)
        setTimeout( () => {
            playAudioGetWinner();              
            clearInterval(intervalId2)   
            if(result2 !== null)winnerData.push(result2);
            getValueAndResetIndex(result2)
      
        }, 9000)
        setTimeout( () => {
            playAudioGetWinner();     
            clearInterval(intervalId3)
            if(result3 !== null)winnerData.push(result3);
            getValueAndResetIndex(result3)
        
        }, 11000)
        setTimeout( () => {
            playAudioGetWinner();               
            clearInterval(intervalId4)
            if(result4 !== null)winnerData.push(result4);
            getValueAndResetIndex(result4)
     
        }, 13000)
        setTimeout( () => {
            playAudioGetWinner();     
            clearInterval(intervalId5)
            if(result5 !== null)winnerData.push(result5);
            getValueAndResetIndex(result5)
            
            setAddWinner(true);
            setDisableButt(false);
            stopAudioRoll();
        }, 15000)

        function getValueAndResetIndex(index) {
            
            // Memastikan indeks tidak melebihi panjang array
            if (index >= users.length || index < 0) {
              return null; // Mengembalikan null jika indeks tidak valid
            }
            const newValue = users
            users.splice(index,1);

            setUsers(newValue);
          }
    }

    // axiosJwt.interceptors.request.use(async(config) => {
    //     const currentDate = new Date();
    //     if(expire * 1000 < currentDate.getTime()){
    //         AuthUser.refreshToken().then((e) => {
    //             config.headers.Authorization = `Bearer ${e}`;
    //             setToken(e);
    //             const decoded = jwtDecode(e);
    //             setExpire(decoded.exp);
    //         });  
    //     }
    //     return config;
    // }, (error) => {return Promise.reject(error)})

    async function newRolleteAndUpdate () {
        // console.log('win', winnerData.length);
        playAudiReRoll();
        setCounters(counters+winnerData.length);
        reset();
        const arrData = [
            numbers.id,
            numbers2.id,
            numbers3.id,
            numbers4.id,
            numbers5.id,
        ]

        const data2 = expire;

        await IndexUserAPI.update(arrData, data2).then((e)=>{   
            // setToken(e);
            // const decoded = jwtDecode(e);
            // setExpire(decoded.exp);
            // window.alert(`Data Berhasil Di Edit`)
        })
        setAddWinner(false);
        setWinnerData([]);
        // console.log('win2', winnerData);
    }

  return (
    <div>
        <div className='position-absolute d-flex flex-column' style={{top:'5%', left:'5%', width:'60%'}}>
            <img className='logo-width position-relative' src={logo} alt="logo-infinity-8"/>
        </div>
        <div className='position-absolute d-flex flex-column' style={{top:'5%', right:'5%', width:'60%'}}>        
            <img className='logo-width position-absolute' src={ktLogo} alt="logo-infinity-8" style={{right:'0'}}/>
        </div>

        <div className="p-3">
            <h1 className="text-center text-white">
                { value }
            </h1>
        </div>
        <div className="d-flex justify-content-center">
            <div className="p-4 d-flex justify-content-center width-content-gacha" 
            style={{
            overflow: 'hidden',
            background : 'linear-gradient(to right, #212121, #535353, #212121)',
            borderRadius:'50% 0',
            border : '10px solid',
            borderTopColor : 'red',
            borderBottomColor : 'red'
            }}>
                <table className="">
                {/* <tr>
                    <td style={{color:'gold'}}>
                        <div className='container'>
                            <div className={`elemen elem ${isAnimationRunning ? 'animating' : ''}`} >
                            </div>
                            <div style={{display:'flex', justifyContent:'center', alignItems:'center', height:'inherit', flexDirection:'column'}}>
                                <p className='text-winners' style={{position:'absolute', zIndex:'30', top:'25%'}}>
                                    {numbers.name} 
                                </p>
                                <p className='text-winners' style={{position:'absolute', zIndex:'30', top:'50%'}}>
                                    {numbers.tiktok}
                                </p>
                            </div>
                        </div>
                    </td>
                </tr> */}
                    {/* <tr className='container'>
                        <td className='elemen'>
                            <p className='text-winners'>                    
                                {numbers.email}
                            </p>
                        </td>
                    </tr> */}
                    <tr className="d-flex flex-column justify-content-center align-items-center text-center" style={{border:'2px solid gold', backgroundColor:'black', color:'white', borderRadius:'40px 0'}}>
                        <tr className='' >
                            <td className=''>                                
                                <p className=''>
                                    {numbers.name}
                                </p>
                            </td>
                        </tr>
                        <tr className=''>
                            <td className='d-flex'>
                                <img src={ig} style={{height:'20px', width:'20px', marginRight:'3px'}}/>
                                <p className=''>                    
                                   {numbers.instagram}
                                </p>
                            </td>
                            <td className='d-flex'>
                                <img src={tt} style={{height:'20px', width:'20px', marginRight:'3px'}}/>
                                <p className=''>                    
                                    {numbers.tiktok}
                                </p>
                            </td>
                        </tr>
                    </tr>
                    <tr>
                        <td>----------------------------</td>
                    </tr>
                    <tr className="d-flex flex-column justify-content-center align-items-center text-center" style={{border:'2px solid gold', backgroundColor:'black', color:'white', borderRadius:'40px 0'}}>
                        <tr className=''>
                            <td className=''>
                                <p className=''>   
                                {numbers2.name}
                                </p>
                            </td>
                        </tr>
                        <tr className=''>
                            <td className='d-flex'>
                                <img src={ig} style={{height:'20px', width:'20px', marginRight:'3px'}}/>
                                <p className=''>                    
                                {numbers2.instagram}
                                </p>
                            </td>
                            <td className='d-flex'>
                                <img src={tt} style={{height:'20px', width:'20px', marginRight:'3px'}}/>
                                <p className=''>                    
                                {numbers2.tiktok}
                                </p>
                            </td>
                        </tr>
                    </tr>
                    <tr>
                        <td>----------------------------</td>
                    </tr>
                    <tr className="d-flex flex-column justify-content-center align-items-center text-center" style={{border:'2px solid gold', backgroundColor:'black', color:'white', borderRadius:'40px 0'}}>
                        <tr className=''>
                            <td className=''>
                                <p className=''>   
                                {numbers3.name}
                                </p>
                            </td>
                        </tr>
                        <tr className=''>
                            <td className='d-flex'>
                                <img src={ig} style={{height:'20px', width:'20px', marginRight:'3px'}}/>
                                <p className=''>                     
                                {numbers3.instagram}
                                </p>
                            </td>
                            <td className='d-flex'>
                                <img src={tt} style={{height:'20px', width:'20px', marginRight:'3px'}}/>
                                <p className=''>                    
                                {numbers3.tiktok}
                                </p>
                            </td>
                        </tr>
                    </tr>
                    <tr>
                        <td>----------------------------</td>
                    </tr>
                    <tr className="d-flex flex-column justify-content-center align-items-center text-center" style={{border:'2px solid gold', backgroundColor:'black', color:'white', borderRadius:'40px 0'}}>
                        <tr className=''>
                            <td className=''>
                                <p className=''>   
                                {numbers4.name}
                                </p>
                            </td>
                        </tr>
                        <tr className=''>
                            <td className='d-flex'>
                                <img src={ig} style={{height:'20px', width:'20px', marginRight:'3px'}}/>
                                <p className=''>                     
                                {numbers4.instagram}
                                </p>
                            </td>
                            <td className='d-flex'>
                                <img src={tt} style={{height:'20px', width:'20px', marginRight:'3px'}}/>
                                <p className=''>                    
                                {numbers4.tiktok}
                                </p>
                            </td>
                        </tr>
                    </tr>
                    <tr>
                        <td>----------------------------</td>
                    </tr>
                    <tr className="d-flex flex-column justify-content-center align-items-center text-center" style={{border:'2px solid gold', backgroundColor:'black', color:'white', borderRadius:'40px 0'}}>
                        <tr className=''>
                            <td className=''>
                                <p className=''>   
                                {numbers5.name}
                                </p>
                            </td>
                        </tr>
                        <tr className=''>
                            <td className='d-flex'>
                                <img src={ig} style={{height:'20px', width:'20px', marginRight:'3px'}}/>
                                <p className=''>                      
                                {numbers5.instagram}
                                </p>
                            </td>
                            <td className='d-flex'>
                                <img src={tt} style={{height:'20px', width:'20px', marginRight:'3px'}}/>
                                <p className=''>                    
                                {numbers5.tiktok}
                                </p>
                            </td>
                        </tr>
                    </tr>
                    <tr>
                        <td>----------------------------</td>
                    </tr>
                </table>
            </div>
        </div>

        <>
            <div className="row justify-content-center p-4 m-2">
                { addWinner ? 
                    (
                            <button className="btn w-25" onClick={newRolleteAndUpdate} style={{backgroundColor : 'green', color:'white'}}>Role Lagi</button>
                        ) : (                
                            <button className="btn w-25" onClick={handleClick} style={{backgroundColor : 'green', color:'white'}} disabled={disableButt}>Mulai</button>
                    )    
                }
            </div>
        </>

    <audio ref={audioRefRoll}>
        <source src={roll} type="audio/mpeg" />
        Your browser does not support the audio element.
    </audio>

    <audio ref={audioRefReRoll}>
        <source src={reroll} type="audio/mpeg" />
        Your browser does not support the audio element.
    </audio>

    <audio ref={audioRefGetWinner}>
        <source src={getwinner} type="audio/mpeg" />
        Your browser does not support the audio element.
    </audio>

    </div>
  )
}

export { Dashboard };