import React from "react";
import { Navigate, useRoutes } from 'react-router-dom';
import { Dashboard } from './view/dashboard/index.js';
import { Login } from './view/login.js';

export default function Routes() {

    return (
        useRoutes([
            {
                path : 'login',
                element : <Login/>
            },
            {
                path : "/",
                element : <Dashboard/>
            },
        ])
    )
}
